import PropTypes from 'prop-types';

export default function VideoSection({ youtubeId, id }) {
  return (
    <section className="c-video-section">
      <div className="c-video-section__anchor" id={id} />
      <div className="o-retain o-retain--wall">
        <iframe
          className="c-video-section__video"
          title="Video"
          width="100%"
          src={`https://www.youtube-nocookie.com/embed/${youtubeId}?controls=0&rel=0`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </section>
  );
}

VideoSection.propTypes = {
  youtubeId: PropTypes.string.isRequired,
  id: PropTypes.string,
};
