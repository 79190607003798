import Button from 'components/Button';
import { useTranslation } from 'next-i18next';
import LogoIcon from 'svg/logo-icon.svg';

export default function PriceTableSection() {
  const { t } = useTranslation();

  return (
    <section className="c-price-table">
      <div className="c-price-table__container o-retain o-retain--wall">
        <LogoIcon className="c-price-table__background-image" />

        <h2 className="c-price-table__title">
          Onze prijzen <span>Helder en flexibel</span>
        </h2>
        <div className="c-price-table__card c-price-table__card--main">
          <h3 className="c-price-table__card-title">Alles-in-één oplossing</h3>
          <ul className="c-price-table__card-list">
            <li>Toegang tot ons platform met ATS</li>
            <li>Toegang tot CV-database</li>
            <li>Template werken-bij site</li>
            <li>Unieke bedrijfspagina</li>
            <li>Stel funnels in</li>
            <li>Gratis helpdesk</li>
            <li>Vacatures direct in partnernetwerk</li>
            <li>Dagelijks vacature alerts naar kandidaten</li>
            <li>Toegang tot CV-database, 100 views per maand.</li>
            <li>
              Beheer uw sollicitaties en check statistieken in één dashboard
            </li>
            <li>Onbeperkte aantal gebruikers</li>
            <li>Vacatures continue wisselen met vacatureslots</li>
            <li>Incl. 10 vacatureslots</li>
            <li>Jaarabbonnement</li>
          </ul>
          <p className="c-price-table__price">
            <span>€ 167,50</span> per maand
          </p>
          <div className="c-price-table__cta">
            <Button
              extraClasses="c-button--secondary"
              label="Plan gratis demo"
              href={`mailto:sales@debanensite.nl?subject=${t(
                'footer.emailSubject'
              )}`}
            />
          </div>
          <p className="c-price-table__price">
            <span>€ 1.675,00</span> per jaar (-16,5%)
            <br />
            <strong>Meer vacatureslots? Neem contact met ons op.</strong>
          </p>
        </div>

        <div className="c-price-table__card">
          <h4 className="c-price-table__card-title">CV-database</h4>
          <p className="c-price-table__price">€ 499,00 per maand</p>
        </div>

        <div className="c-price-table__card">
          <h4 className="c-price-table__card-title">Werken-bij website</h4>
          <p className="c-price-table__price">
            Gratis template werken-bij site
            <br />
            Custom op aanvraag
          </p>
        </div>

        <div className="c-price-table__card">
          <h4 className="c-price-table__card-title">
            Losse vacatures plaatsen
          </h4>
          <p className="c-price-table__price">
            € 399,00 per vacature
            <br />
            (90 dagen)
          </p>
        </div>

        <div className="c-price-table__card">
          <h4 className="c-price-table__card-title">Jobmarketing</h4>
          <p className="c-price-table__price">
            Boost additioneel vacatures op extra kanalen.
            <br />
            Vraag vrijblijvend een campagne advies aan.
          </p>
        </div>
      </div>
    </section>
  );
}
