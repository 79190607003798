import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Layout from 'components/Layout';
import hasAuthToken from 'lib/hasAuthToken';
import { useTranslation } from 'next-i18next';
import FilterHero from '@organisms/FilterHero/FilterHero';
import SelectedCompanies from '@organisms/SelectedCompanies';
import VideoSection from '@organisms/VideoSection/VideoSection';
import ContentGrid from '@organisms/ContentGrid/ContentGrid';
import DatabaseVisual2 from 'img/database-visual-2.webp';
import WerkenBijUrl from 'img/werkenbij-url.webp';
import JobMarketingVisual2 from 'img/job-marketing-visual-2.webp';
import PlaceholderPerson from 'img/placeholder-person.webp';
import DashboardVisual from 'img/dashboard-visual.webp';
import WebsiteVisual from 'img/website-visual.webp';
import FullBannerSection from '@organisms/FullBannerSection/FullBannerSection';
import PriceTableSection from '@organisms/PriceTableSection/PriceTableSection';

const EXPLANATION_ID = 'dbs-explanation';

const AddJobPostingPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('addJobPosting.pageTitle')}>
      <FilterHero
        title={t('addJobPosting.heroTitle')}
        text={t('addJobPosting.heroText')}
        cta={{
          label: t('addJobPosting.heroCtaLabel'),
          href: `#${EXPLANATION_ID}`,
        }}
        variant="employers"
      />

      <SelectedCompanies
        title="Deze bedrijven gingen u voor"
        images={[
          {
            src: '/img/cirfood.webp',
            width: 180,
            height: 180,
            alt: 'Cirfood',
          },
          {
            src: '/img/totalenergies.webp',
            width: 180,
            height: 180,
            alt: 'TotalEnergies',
          },
          {
            src: '/img/schoonderwoerd.webp',
            width: 180,
            height: 180,
            alt: 'Schoonderwoerd',
          },
          { src: '/img/varex.webp', width: 180, height: 180, alt: 'Varex' },
          {
            src: '/img/hollandcasino.webp',
            width: 180,
            height: 180,
            alt: 'Holland Casino',
          },
        ]}
      />

      <VideoSection id={EXPLANATION_ID} youtubeId="jlm_1plC1eA" />

      <ContentGrid
        flipped
        background="light"
        items={[
          {
            id: 'grid-1',
            title: 'CV database',
            text: 'Waarom wachten op sollicitaties als je vandaag nog zelf de beste kandidaten kunt vinden? Met onze uitgebreide cv-database zoek je eenvoudig in meer dan 500.000 actuele cv’s in alle sectoren. En met meer dan 2.000 nieuwe cv’s per week blijft de database altijd up-to-date.\n\nFilter op regio, functietitel en meer, en benader direct de juiste kandidaten.',
            image: {
              src: DatabaseVisual2,
              alt: '',
              width: 576,
              height: 619,
            },
          },
          {
            id: 'grid-2',
            title: 'Jobmarketing',
            text: 'Met onze jobmarketing tool verspreid je jouw vacatures in één keer via meer dan <strong>3.000 relevante kanalen</strong> — van bekende vacaturesites en social media tot nicheplatformen. Je beheert alles eenvoudig vanuit één centraal dashboard, waar je zelf je campagne aanmaakt, instelt en direct start.\n\nDe jobmarketing tool is een <strong>additionele dienst</strong> die je flexibel inzet naast je standaard vacatureplaatsingen, zodat je jouw bereik optimaal vergroot wanneer dat nodig is. Bovendien staan onze specialisten klaar met persoonlijk campagne-advies, zodat je altijd de juiste doelgroep bereikt met de juiste boodschap.',
            image: {
              src: JobMarketingVisual2,
              alt: '',
              width: 631,
              height: 588,
            },
          },
          {
            id: 'grid-3',
            title: 'Jeroen Jonker - HR manager Kliko Groep',
            text: "Op basis van de vacatures wordt in samenspraak met deBanenSite de best passende campagne gezocht. De ene keer is dat social media, de andere keer specifieke vak websites. Dit levert vaak veel en goede cv's op. Voor sommige vacatures kregen we meer dan 40 sollicitanten!, zelfs in deze krappe arbeidsmarkt.\n\nDe samenwerking met de medewerkers van deBanenSite bevalt ons erg goed, vandaar dat wij alle vacatures hier plaatsen. Als extra het kunnen zoeken in de CV database, waar kandidaten kunnen aangeven dat werkgevers hun cv mogen bekijken, een extra kans om geschikte kandidaten te vinden.",
            variant: 'quote',
            image: {
              src: PlaceholderPerson,
              alt: '',
              width: 138,
              height: 138,
            },
          },
          {
            id: 'grid-4',
            title: 'Solliciteren beheren',
            text: 'Houd grip op je werving met onze gebruiksvriendelijke sollicitatiebeheer tool. Alle binnenkomende sollicitaties komen centraal binnen in één systeem, waar je ze eenvoudig kunt beheren, beoordelen en opvolgen. Zet kandidaten met één klik in de juiste fase van je wervingsproces — van eerste selectie tot afwijzing of uitnodiging — en voeg makkelijk notities toe per kandidaat.',
            image: {
              src: DashboardVisual,
              alt: '',
              width: 1187,
              height: 725,
            },
          },
          {
            id: 'grid-5',
            title: 'Werken-bij site',
            text: 'Ontdek onze eenvoudig te gebruiken werken-bij site editor en creëer in no-time een professionele werken-bij site. Geef kandidaten direct een goed beeld van jouw organisatie en cultuur.\n\nLiever een volledig op maat gemaakte werken-bij site? Dat kan ook! Wij bouwen graag een custom site die perfect aansluit bij jouw employer branding.',
            image: {
              src: WebsiteVisual,
              alt: '',
              width: 1025,
              height: 655,
            },
            smallImage: {
              src: WerkenBijUrl,
              alt: '',
              width: 411,
              height: 111,
            },
          },
        ]}
      />

      <PriceTableSection />

      <FullBannerSection
        background="light"
        title="Meer weten?"
        cta={{ label: 'Bel ons op 072-5744544', href: 'tel:0725744544' }}
      />
    </Layout>
  );
};

export async function getServerSideProps({ req, res, locale }) {
  if (!hasAuthToken(req)) {
    res.setHeader(
      'Cache-Control',
      'public, max-age=300, s-maxage=300, stale-while-revalidate=600'
    );
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default AddJobPostingPage;
