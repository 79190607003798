import PropTypes from 'prop-types';
import React from 'react';
import Image from 'next/image';

const SelectedCompanies = ({ title, images }) => (
  <section className="c-selected-companies">
    <div className="o-retain o-retain--wall">
      <h2 className="c-selected-companies__title">{title}</h2>
      <ul className="c-selected-companies__list">
        {images.map(image => (
          <li className="c-selected-companies__list-item" key={image.src}>
            <Image
              src={image.src}
              alt={image.alt}
              width={image.width}
              height={image.height}
              className="c-selected-companies__image"
            />
          </li>
        ))}
      </ul>
    </div>
  </section>
);

SelectedCompanies.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SelectedCompanies;
